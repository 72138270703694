<template>
  <div class="report">
    <div class="export-button">
      <el-button type="success" size="large" @click="handleClickExportPdf">{{
        langObj.report.export_report
      }}</el-button>

      <el-button
        type="primary"
        size="large"
        :disabled="isDisabled"
        @click="handleClickChangeReportShow"
        >{{ reportChangeShowText }}</el-button
      >
      <!-- <a href="javascript:void(0);" data-catalog="report_mrt" @click="onJump">Mrt</a> -->
    </div>
    <div class="report-html-box" :class="{ 'report-fixed': reportHide }">
      <div id="report_home" ref="reportHomeRef" class="report-home-box">
        <reportHome
          :basicReportInfo="basicReportInfo"
          :wireRopeData="wireRopeData"
        ></reportHome>
      </div>
      <div id="report_mrt" class="report-mrt-box">
        <reportMrt
          :wireRopeData="wireRopeData"
          :basicReportInfo="basicReportInfo"
          :ruleFormParam="ruleFormParam"
          :flawDailyDataClick="flawDailyDataClick"
          :pageSize="totalPage"
          :clickReport="clickReport"
        ></reportMrt>
      </div>

      <div
        :id="'report_flaw' + index"
        class="report-flaw-box"
        v-for="(item, index) in flawListGroupData"
        :key="index"
      >
        <reportFlaw
          :flawListGroup="item"
          :basicReportInfo="basicReportInfo"
          :pageNum="3 + index"
          :pageSize="totalPage"
          :clickReport="clickReport"
        ></reportFlaw>
      </div>

      <div v-if="!ruleFormParam.isCuver">
        <div
          class="report-wave-box"
          :id="'report_curve' + index"
          v-for="(ropeData, index) in echartsDataGroup"
          :key="index"
        >
          <reportCharts
            :basicReportInfo="basicReportInfo"
            :ruleFormParam="ruleFormParam"
            :flawDailyDataClick="flawDailyDataClick"
            :pageNum="flawPageEnd + index + 1"
            :pageSize="totalPage"
            :reportHide="reportHide"
            :ropeG2Data="ropeData"
            :index="index"
          ></reportCharts>
        </div>
      </div>
      <div v-else>
        <div
          class="report-wave-box"
          :id="'report_curve_sensor' + index"
          v-show="ruleFormParam.isCuver"
          v-for="(sensorData, index) in echartsSensorGroup"
          :key="index"
        >
          <reportSensor
            :basicReportInfo="basicReportInfo"
            :ruleFormParam="ruleFormParam"
            :flawDailyDataClick="flawDailyDataClick"
            :pageNum="flawPageEnd + index + 1"
            :pageSize="totalPage"
            :reportHide="reportHide"
            :sensorData="sensorData"
            :index="index"
          ></reportSensor>
        </div>
      </div>
    </div>
    <div class="loading-box" style="margin-top: 20px" v-show="isDisabled">
      <dv-loading>{{ langObj.report.report_loading }}……</dv-loading>
    </div>
    <div class="export-button">
      <el-button
        type="success"
        size="large"
        v-show="!reportHide"
        @click="handleClickExportPdf"
        >{{ langObj.report.export_report }}</el-button
      >
      <!-- <el-button
        type="primary"
        size="large"
        @click="handleClickChangeReportShow"
        >{{ reportChangeShowText }}</el-button
      > -->
      <!-- <a href="javascript:void(0);" data-catalog="report_mrt" @click="onJump">Mrt</a> -->
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, reactive, computed, watch } from "vue";
// import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import utils from "../../../common/utils";
import reportHome from "@/components/detail/report/child/reportHome";
import reportMrt from "@/components/detail/report/child/reportMrt";
import reportFlaw from "@/components/detail/report/child/reportFlaw";
import reportCharts from "@/components/detail/report/child/cuver/reportCharts";
import reportSensor from "@/components/detail/report/child/cuver/reportSensor";
import { ElMessage } from "element-plus";
import {
  httpGetHistoryCurveHook,
  httpGetSingleCurveHook,
} from "@/hooks/detail/cuver.js"; //引入封装的Antv G2 组件
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  filterHtmlToCanvas,
  getCommonCanvasPdfImage,
  filterFlawDailyByRuleForm,
  filterStationFlawDailyByRuleForm,
  computedFlawListByPage,
} from "@/hooks/detail/report.js";

import { httpGetStationFlawDataHook } from "@/hooks/detail/detail.js"; //定义 ruleFrom rules

let isDisabled = ref(true);
let reportHide = ref(true); //是否隐藏报告
const flawListGroupData = reactive([]);
const pageNum = 2; //起始页
let flawPageEnd = ref(0);
let totalPage = ref(0); //总页码

let pageTimer = null;
let filterValueTimer = null;
const route = useRoute();
const sensorDataGroup = reactive([]);
const echartsDataGroup = reactive([]);
const echartsSensorGroup = reactive([]);
const store = useStore();

const langObj = computed(() => {
  return store.state.lang.list;
});

const ruleForm = reactive({
  id: 0,
  filter_value: "",
  filter_level: 0,
  sort: 0,
  reverse: 0,
});

const props = defineProps({
  id: [Number, String],
  exportPdf: Boolean,
  basicReportInfo: Object, //  报告基本信息
  wireRopeData: Object, //钢丝绳基本信息
  flawDailyDataClick: Object, //点击当前日期的损伤数据信息
  ruleFormParam: Object, //参数过滤
  clickReport: Boolean,
});

const handleClickExportPdf = async () => {
  if (reportHide.value) {
    ElMessage({
      message: "请先预览报告",
      type: "warning",
      offset: 150,
      duration: 3000,
      onClose: () => {},
    });
  } else {
    ElMessage({
      message: "正在下载报告请稍后~~~~",
      type: "success",
      offset: 150,
      duration: 2000,
      onClose: () => {},
    });

    const pdfObj = new jsPDF("", "pt", "a4"); //创建pdf对象
    //报告主页开始
    const reportHome = document.getElementById("report_home");
    const canvasHome = await filterHtmlToCanvas(reportHome);
    await getCommonCanvasPdfImage(canvasHome, pdfObj, true);

    //MRT检测主报告开始
    const reportMrt = document.getElementById("report_mrt");
    const canvasMrt = await filterHtmlToCanvas(reportMrt);
    await getCommonCanvasPdfImage(canvasMrt, pdfObj);

    //损伤序列表
    for (let i = 0; i < flawListGroupData.length; i++) {
      //损伤列表报告开始
      const reportFlaw = document.getElementById("report_flaw" + i);
      const canvasFlaw = await filterHtmlToCanvas(reportFlaw);
      await getCommonCanvasPdfImage(canvasFlaw, pdfObj);
    }

    if (!props.ruleFormParam.isCuver) {
      for (let i = 0; i < echartsDataGroup.length; i++) {
        const reportCurve = document.getElementById("report_curve" + i);
        const canvasCurve = await filterHtmlToCanvas(reportCurve);
        await getCommonCanvasPdfImage(canvasCurve, pdfObj);
      }
    } else {
      for (let i = 0; i < sensorDataGroup.length; i++) {
        const reportCurveSensor = document.getElementById(
          "report_curve_sensor" + i
        );
        const canvasCurveSensor = await filterHtmlToCanvas(reportCurveSensor);
        await getCommonCanvasPdfImage(canvasCurveSensor, pdfObj);
      }
    }

    const reportDate = props.basicReportInfo.reprotDate;
    pdfObj.save(`检测报告_${reportDate}.pdf`); //导出pdf
  }
};

const handleClickChangeReportShow = () => {
  reportHide.value = !reportHide.value;
};

const reportChangeShowText = computed(() => {
  const preview = langObj.value.report.preview_report;
  const hide = langObj.value.report.hide_report;
  return reportHide.value ? preview : hide;
});

const filterFlawDailyList = async () => {
  let flawDailyList = "";
  let filterFlawDaily = "";
  // 当日检测 只添加了testLength 一个键值对
  if (Object.keys(props.flawDailyDataClick).length > 1) {
    flawDailyList = props.flawDailyDataClick.countFlaw;
    flawDailyList = flawDailyList == undefined ? [] : flawDailyList;
    filterFlawDaily = filterFlawDailyByRuleForm(flawDailyList, props);
  } else {
    //获取当天日期的数据
    ruleForm.id = route.params.id;
    const flawDailyList = await httpGetStationFlawDataHook(ruleForm, false);
    filterFlawDaily = filterStationFlawDailyByRuleForm(flawDailyList, props);
  }

  //对已经过滤的损伤列表进行分组
  flawListGroupData.length = 0;
  flawListGroupData.push(...computedFlawListByPage(filterFlawDaily));
  flawPageEnd.value = totalPage.value = pageNum + flawListGroupData.length;
};

// const changeReportChartsByIsCuver = (isCuver) => {
//   cuverCompent.value = isCuver ? "reportSensor" : "reportCharts";
// };

const initHistoryCurveData = async (currentData) => {
  let currentDate = utils.formatDate(); //当日日期
  let echartsData = {};
  if (Object.keys(currentData).length > 0) {
    currentDate = currentData.date;
    echartsData = await httpGetHistoryCurveHook(currentDate, route.params.id);
  } else {
    echartsData = await httpGetSingleCurveHook(route.params.id);  //当天的波形图，查这个接口
  }

  const { echartsDataRope, sencorEchartsData } = echartsData;

  isDisabled.value = false;
  echartsDataGroup.length = 0;
  sensorDataGroup.length = 0;
  if (echartsDataRope) {
    filterEchartsDataGroupByPageLength(echartsDataRope);
    filterEchartsSencorGroupByPageLength(sencorEchartsData);
    totalPage.value = flawPageEnd.value + echartsDataGroup.length;
  }
};

//对波形图的钢丝绳的数据进行分组处理 根据每页的长度   ehcartsData
const filterEchartsDataGroupByPageLength = (echartsData) => {
  const pageLength = Number(props.ruleFormParam.pageLength);
  let startLength = echartsData[0][0] + pageLength;
  const ropeGroup = [];
  let tempArr = [];
  for (let i = 0; i < echartsData.length; i++) {
    tempArr.push(echartsData[i]);
    if (echartsData[i][0] > startLength || i == echartsData.length - 1) {
      ropeGroup.push(tempArr);
      tempArr = [];
      startLength += pageLength;
    }
  }
  echartsDataGroup.length = 0;
  echartsDataGroup.push(...ropeGroup);
};

//对波形图的sensor的数据进行分组处理 根据每页的长度   ehcartsData
const filterEchartsSencorGroupByPageLength = (cuverData) => {
  const pageLength = Number(props.ruleFormParam.pageLength);
  let cuverGroup = [];
  for (let i = 0; i < cuverData.length; i++) {
    let ropeGroup = [];
    let tempArr = [];
    let startLength = cuverData[0][0][0] + pageLength;
    for (let j = 0; j < cuverData[i].length; j++) {
      tempArr.push(cuverData[i][j]);
      if (cuverData[i][j][0] > startLength || j == cuverData[i].length - 1) {
        ropeGroup.push(tempArr);
        tempArr = [];
        startLength += pageLength;
      }
    }
    cuverGroup.push(ropeGroup);
  }
  const echartsGroup = [];

  for (let i = 0; i < cuverGroup[0].length; i++) {
    let tempGroup = [];
    for (let j = 0; j < cuverGroup.length; j++) {
      tempGroup.push(cuverGroup[j][i]);
    }
    echartsGroup.push(tempGroup);
  }
  echartsSensorGroup.length = 0;
  echartsSensorGroup.push(...echartsGroup);
};

watch(
  () => [props.ruleFormParam.filter_level, props.ruleFormParam.filter_value],
  () => {
    clearTimeout(filterValueTimer);
    filterValueTimer = setTimeout(() => {
      filterFlawDailyList();

      initHistoryCurveData(props.flawDailyDataClick); //初始化波形图数据
    }, 500);
  },
  { deep: true, immediate: false }
);

watch(
  () => props.ruleFormParam.pageLength,
  () => {
    if (props.ruleFormParam.pageLength >= 10) {
      clearTimeout(pageTimer); //清除未执行的定时器
      pageTimer = setTimeout(() => {
        isDisabled.value = true;
        reportHide.value = true;
        filterFlawDailyList();

        initHistoryCurveData(props.flawDailyDataClick); //   初始化波形图数据
      }, 500);
    }
  },
  { deep: true, immediate: false }
);

watch(
  () => props.flawDailyDataClick,
  () => {
    isDisabled.value = true;
    reportHide.value = true;
    filterFlawDailyList();
    initHistoryCurveData(props.flawDailyDataClick); //初始化波形图数据
    echartsDataGroup.length = 0;
    sensorDataGroup.length = 0;
  },
  { deep: true, immediate: false }
);

onMounted(() => {
  nextTick(async () => {
    // document.addEventListener("contextmenu", function (e) {
    //   e.preventDefault();
    // });
  });
});
</script>

<style lang="scss" scoped>
.report {
  color: #fff;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  .export-button {
    text-align: center;
    margin-top: 0.25rem;
    clear: both;
  }
  .report-html-box {
    // position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    .report-home-box {
      width: 1000px;
    }
    .report-mrt-box {
      margin-top: 20px;
      width: 1000px;
    }
    .report-flaw-box {
      margin-top: 20px;
      width: 1000px;
    }
    .report-wave-box {
      height: 1300px;
      margin-top: 20px;
      width: 1000px;
      background-color: #fff;
    }
  }
  .report-fixed {
    position: fixed;
  }
  .loading-box {
    & :deep .loading-tip {
      margin-top: 10px;
    }
  }
}
</style>