<template>
  <div class="wave-box" id="wave_box">
    <!-- <div class="rope-charts" v-show="cuverFlag">
      <div id="container" class="wave-antv"></div>
    </div> -->
    <div class="rope-charts" v-show="!cuverFlag">
      <div id="curverEcharts" ref="curverEcharts" class="wave-antv"></div>
    </div>
    <div class="tooltip-box" :style="tooltipStyle" v-show="tooTipVis">
      <ul>
        <!-- <div style="margin-left: 5px; margin-bottom: 10px">
          实时位置: {{ parseFloat(pointLength).toFixed(2) }}m
        </div> -->
        <div style="margin-left: 5px; margin-bottom: 10px">损伤数据:</div>
        <li v-for="(item, index) in pointDataArr" :key="index">
          <div class="flaw-box">
            <p>损伤: {{ item[2].toFixed(2) }}m</p>
            <p>量值: {{ item[3].toFixed(1) }}%</p>
            <p>
              等级:
              <span
                style="margin-left: 3px"
                :class="[filterDamageLevelColor(item[4])]"
              >
                {{ filterDamageLevelText(item[4]) }}</span
              >
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="sensor-dialog">
      <el-dialog
        v-model="dialogVisible"
        title="传感器波形图"
        width="80%"
        :close-on-click-modal="false"
        @close="closeSensorDlalog"
      >
        <div>
          <waveSensor
            :sensorG2Data="sensorG2Data"
            :echartsSencorData="echartsSencorData"
            :isDestory="isDestory"
            :sensorsNum="sensorsNum"
          ></waveSensor>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import * as echarts from "echarts";
import {
  nextTick,
  onMounted,
  reactive,
  watch,
  ref,
  onBeforeUnmount,
  toRefs,
} from "vue";
// import { getAntvG2Charts } from "@/hooks/detail/antv/cuver/waveCharts.js"; //引入封装的Antv G2 组件
import { initCurverEchatsOptions } from "@/hooks/detail/echarts/curver/curverEcharts.js";
// import { getSencorAntvG2Charts } from "@/hooks/detail/antv/cuver/sensorCharts.js"; //引入封装的Antv G2 组件
import { httpGetSingleCurveHook } from "@/hooks/detail/cuver.js"; //引入封装的Antv G2 组件
// import { GetSingleCurve } from "@/common/api/index/api.js";
import waveSensor from "@/components/detail/cuver/waveSensor";
import { GetStationFlawData } from "@/common/api/index/api.js";
import {
  filterDamageLevelTextHook,
  filterDamageLevelColorHook,
} from "@/hooks/detail/detail.js"; //定义 ruleFrom rules

import { throttle1, debounce } from "../../../common/scrollToSlow.js";
import { useStore } from "vuex";
export default {
  name: "",
  setup(props, context) {
    // 初始化图表实例
    let chart = null;
    let sencorChart = null;
    let chartRef = ref({});
    let imgSrc = ref("");
    let dialogVisible = ref(false);
    let isDestory = ref(false);
    //const filterCuverData = reactive([]);
    // let ropeG2Data = [];
    let sensorG2Data = reactive([]);
    let echartsRopeData = [];
    let echartsSencorData = reactive([]);
    let sensorsNum = ref(0);
    const harmList = reactive([]);
    let tooTipVis = ref(false);
    let moveFlag = ref(true);
    let moveTimer = ref(null);
    let stepNum = 5;
    let chartScale = {
      maxLength: 0,
      minLength: 0,
      maxValue: 0,
      minValue: 0,
      zoomScaleY: false,
    };
    const store = useStore();
    const curverEcharts = ref(null);
    let echartsObj = null;

    let rightClick = false;
    const hoverPoineData = reactive({
      pointDataArr: [],
      pointLength: "",
    });

    const tooltipStyle = reactive({
      left: "100px",
      top: "",
    });

    const ruleForm = reactive({
      id: 0,
      filter_value: "",
      filter_level: 0,
      sort: 0,
      reverse: 0,
    });

    const initAntvG2Charts = () => {
      chart = new Chart({
        container: document.querySelector("#container"),
        autoFit: true,
        // defaultInteractions: [],
      });

      chartRef.value = chart;
      context.emit("getChartRef", chartRef.value);
      // // tooltip 的更新
      chart.on("tooltip:change", (e) => {
        //hoverPoineData.pointDataArr = e.data.items;
        clearTimeout(moveTimer.value);
        moveTimer.value = setTimeout(() => {
          hoverPoineData.pointDataArr = fixHoverPoineData(e.data.items);
          hoverPoineData.pointLength = e.data.title;
        }, 200);
      });

      chart.on("line:mousemove", (e) => {
        tooTipVis.value = true;
        let left = e.x;
        let top = e.y;
        throttle(left, top);
        // tooltipStyle.left = left + 100 + "px";
        // tooltipStyle.right = right + "px";
      });

      chart.on("line:mouseleave", () => {
        tooTipVis.value = false;
      });
    };

    const httpGetStationFlawData = async (id) => {
      ruleForm.id = id;
      harmList.length = 0;
      const res = await GetStationFlawData(ruleForm);
      harmList.push(...res.data.flaw_list);
    };

    const initEchartsCurveById = async (id) => {
      httpGetStationFlawData(id);
      //获取钢丝绳和传感器数据
      const { echartsDataRope, sencorEchartsData, sensors } =
        await httpGetSingleCurveHook(id);
      // ropeG2Data = ropeG2DataRt;
      echartsRopeData = echartsDataRope;
      echartsSencorData.length = 0;
      for (let i = 0; i < sencorEchartsData.length; i++) {
        echartsSencorData.push(sencorEchartsData[i]);
      }
      sensorsNum.value = sensors;

      if (echartsRopeData) {
        const echartsRopeDataSort = [...echartsRopeData];
        // chartScale.maxLength = cuverData.end_pos;
        // chartScale.minLength = cuverData.start_pos;

        getMaxMin(echartsRopeDataSort);
      }

      //改用百度echats
      initCurverEchartsFun(echartsRopeData);
    };

    const throttle = (left, top, interval = 300) => {
      if (!moveFlag.value) return;
      moveFlag.value = false;
      setTimeout(() => {
        tooltipStyle.left = left - 250 + "px";
        tooltipStyle.top = 0;
        moveFlag.value = true;
      }, interval);
    };

    const fixHoverPoineData = (pointDataArr) => {
      for (let i = 0; i < pointDataArr.length; i++) {
        let selectObj = [];
        let pointLength = parseInt(pointDataArr[i].title); //不能四舍五入
        selectObj = harmList.filter(
          (v) => pointLength == parseInt(v[2]) && v[1] == i + 1
        );
        pointDataArr[i].flawData = selectObj;
      }

      return pointDataArr;
    };

    const fixHoverPoineDataEcharts = (params) => {
      let selectObj = [];
      let pointLength = parseInt(params.value[0]); //不能四舍五入
      selectObj = harmList.filter(
        (v) => pointLength == parseInt(v[2]) && v[1] == params.seriesIndex + 1
      );

      return selectObj;
    };

    const filterDamageLevelText = (status) => {
      return filterDamageLevelTextHook(status);
    };

    const filterDamageLevelColor = (status) => {
      return filterDamageLevelColorHook(status);
    };

    // 百度echarts line 初始化
    const initCurverEchartsFun = (echartsRopeData) => {
      if (echartsObj == null) {
        echartsObj = echarts.init(document.querySelector("#curverEcharts"));
      }

      const options = initCurverEchatsOptions(echartsRopeData, chartScale);
      echartsObj.setOption(options);

      echartsObj.on("mousemove", "series", function (params) {
        tooTipVis.value = true;
        let left = params.event.offsetX;
        let top = params.event.offsetY;
        throttle(left, top);
        hoverPoineData.pointDataArr = fixHoverPoineDataEcharts(params);
        hoverPoineData.pointLength = params.value;
      });

      echartsObj.on("mouseout", "series", function () {
        tooTipVis.value = false;
      });
    };

    watch(
      () => props.id,
      (id) => {
        nextTick(() => {
          initEchartsCurveById(id);
        });
      },
      { deep: true, immediate: true }
    );

    watch(
      () => props.cuverFlag,
      (flag) => {
        dialogVisible.value = flag;
        if (!flag) return;
        setTimeout(() => {
          nextTick(() => {
            //getSencorAntvG2Charts(sencorChart, sensorG2Data);
          });
        }, 500);
      },
      { deep: true, immediate: true }
    );

    watch(
      () => props.resetNum,
      () => {
        if (!props.cuverFlag) {
          echartsObj.clear();
          initCurverEchartsFun(echartsRopeData);
        }
      },
      { deep: true, immediate: false }
    );

    watch(
      () => props.zoomScaleY,
      (flag) => {
        chartScale.zoomScaleY = flag;
        initCurverEchartsFun(echartsRopeData);
      },
      { deep: true, immediate: false }
    );

    watch(
      () => store.state.newTimes,
      () => {

        echartsObj && echartsObj.clear();
        initEchartsCurveById(props.id);
      },
      { deep: true, immediate: false }
    );

    const closeSensorDlalog = () => {
      context.emit("changeCuverFlagVis", false);
    };

    const getMaxMin = (echartsData) => {
      const res = echartsData.sort((a, b) => b[0] - a[0]);
      chartScale.maxLength = res[0][0];
      chartScale.minLength = res[res.length - 1][0];
      const valueArr = echartsData.map((v) => v[1]);
      const valueSort = valueArr.sort((a, b) => b - a);
      chartScale.maxValue = valueSort[0] + 100;
      chartScale.minValue = valueSort[valueSort.length - 1] - 100;
    };

    const eventmMousewheelCuver = () => {
      document.querySelector("#container").addEventListener(
        "mousewheel",
        throttle1((e) => {
          console.log(e);
          let step = e.wheelDelta > 0 ? stepNum : -stepNum;
          if (!rightClick) {
            //X轴放大和缩小
            if (e.wheelDelta > 0) {
              if (
                Math.abs(chartScale.maxLength - chartScale.minLength) >
                stepNum * 2
              ) {
                chartScale.minLength += step;
                chartScale.maxLength -= step;
              }
            } else {
              chartScale.minLength += step;
              chartScale.maxLength -= step;
            }
          } else {
            // Y轴放大和缩小

            if (e.wheelDelta > 0) {
              if (
                Math.abs(chartScale.maxValue - chartScale.minValue) >
                stepNum * 2
              ) {
                chartScale.minValue += step * 2;
                chartScale.maxValue -= step * 2;
              }
            } else {
              chartScale.minValue += step * 2;
              chartScale.maxValue -= step * 2;
            }
          }
          //chart.clear();
          // chart.data(ropeG2Data);
          chart.scale({
            length: {
              range: [0, 1],
              min: parseInt(chartScale.minLength),
              max: parseInt(chartScale.maxLength),
              tickCount: 10,
            },
            value: {
              min: parseInt(chartScale.minValue),
              max: parseInt(chartScale.maxValue),
              nice: true,
            },
          });
          chart.render();
        })
      );
    };

    const eventClickMouseButton = () => {
      document
        .querySelector("#curverEcharts")
        .addEventListener("mousedown", (e) => {
          if (e.button == 0) {
            rightClick = true;
            chartScale.zoomScaleY = true;
            // initCurverEchartsFun(ropeG2Data)
          }
        });

      document
        .querySelector("#curverEcharts")
        .addEventListener("mouseup", (e) => {
          if (e.button == 0) {
            rightClick = false;
            chartScale.zoomScaleY = false;
            // initCurverEchartsFun(ropeG2Data)
          }
        });
    };

    onMounted(() => {
      nextTick(() => {
        window.addEventListener(
          "resize",
          debounce(() => {
            echartsObj.resize();
            // echartsObj.setOption(optionsConfig.option);
          })
        );
      });
    });

    onBeforeUnmount(() => {
      isDestory.value = true;
      if (chart) chart.destroy();
      if (sencorChart) sencorChart.destroy();
    });

    return {
      chartRef,
      sensorG2Data,
      imgSrc,
      dialogVisible,
      isDestory,
      sensorsNum,
      harmList,
      tooltipStyle,
      tooTipVis,
      curverEcharts,
      echartsSencorData,
      echartsObj,
      ...toRefs(hoverPoineData),
      closeSensorDlalog,
      filterDamageLevelText,
      filterDamageLevelColor,
      eventmMousewheelCuver,
      initAntvG2Charts,
      eventClickMouseButton,
      //toDataURL,
      //filterCuverData
    };
  },
  data() {
    return {};
  },
  props: {
    id: [Number, String],
    resetNum: [Number, String],
    cuverFlag: Boolean,
    zoomScaleY: Boolean,
  },
  components: {
    waveSensor,
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.wave-box {
  position: relative;
  width: 100%;
  .rope-charts {
    .wave-antv {
      width: 90%;
      height: 5.625rem;
      margin: 20px auto;
      padding: 20px;
      background-color: #f0f0f0;
      box-shadow: 0 0 5px #ccc;
      border-radius: 10px;
    }
  }
  .sensor-box {
    width: 100%;
    //  height: 6.5rem;
    //  overflow-y: scroll;
    .sensor-antv {
      width: 90%;
      // height: 5.625rem;
      height: 6rem;
      margin: 20px auto;
      padding: 20px;
      background-color: #f0f0f0;
    }
  }
  & :deep .el-dialog {
    background-color: #021e33;
  }
  & :deep .el-dialog__title {
    color: #fff;
  }
  .tooltip-box {
    background-color: #031f35;
    position: absolute;
    width: 3.125rem;
    left: 0.375rem;
    top: 0.625rem;
    font-size: 0.1625rem;
    color: #fff;
    padding: 0.0625rem;
    ul {
      li {
        margin-bottom: 0.0625rem;
        p {
          display: flex;
          justify-content: space-between;
          padding: 0.0625rem;
          span {
            display: block;
          }
        }
        .flaw-box {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>