<template>
  <div class="report-container">
    <div class="main">
      <reportHeader
        :basicReportInfo="basicReportInfo"
        :reportTitle="langObj.report.mrt_report"
        :pageSize="pageSize"
        :pageNum="pageNum"
      ></reportHeader>
      <div class="title">
        <p>{{langObj.report.magnetic_image}}</p>
      </div>
      <!-- <div :id="'report_wave' + index" class="report-wave"></div> -->
      <div
        :id="'curverEcharts' + index"
        ref="curverEcharts"
        class="report-wave"
      ></div>
    </div>
  </div>
</template>

<script>

import { nextTick, onMounted, reactive, watch ,computed} from "vue";

// import { Chart } from "@antv/g2";
import * as echarts from "echarts";
// import { useRoute } from "vue-router";
import reportHeader from "@/components/detail/report/child/reportHeader";
import { initCurverEchatsOptions } from "@/hooks/detail/echarts/curver/curverEcharts.js";
import { useStore } from "vuex";
export default {
  name: "",
  setup(props) {
    const ruleFrom = reactive({
      id: 0,
      fname: "",
      date: "2023-03-26",
      type: 0,
      isFilterPole: 0,
    });

    // const cuverData = reactive({
    //   sensors: 0, // 传感器数量
    //   start_pos: 0, // 开始位置
    //   end_pos: 0, // 结束位置
    //   sample_step: 0, // 步长 采样进度
    // });

    // let chart = null;
    let echartsObj = null;

    let chartScale = {
      maxLength: 0,
      minLength: 0,
      maxValue: 0,
      minValue: 0,
      zoomScaleY: false,
    };


    const store = useStore();

    const langObj = computed(() => {
      return store.state.lang.list;
    });

    const getMaxMin = (echartsData) => {
      const res = echartsData.sort((a, b) => b[0] - a[0]);
      chartScale.maxLength = res[0][0];
      chartScale.minLength = res[res.length - 1][0];
      const valueArr = echartsData.map((v) => v[1]);
      const valueSort = valueArr.sort((a, b) => b - a);
      chartScale.maxValue = valueSort[0] + 100;
      chartScale.minValue = valueSort[valueSort.length - 1] - 100;
    };

    // 百度echarts line 初始化
    const initCurverEchartsFun = (echartsRopeData) => {
      getMaxMin(echartsRopeData);
      echartsObj = echarts.init(document.querySelector("#curverEcharts"+props.index));
      setTimeout(() => {
        const options = initCurverEchatsOptions(echartsRopeData, chartScale);
        echartsObj.setOption(options);
      }, 500);
    };

    watch(
      () => props.flawDailyDataClick,
      () => {
        if (!props.reportHide) {

          initCurverEchartsFun(props.ropeG2Data);
        }
      },
      { deep: true, immediate: false }
    );

    watch(
      () => props.reportHide,
      () => {
        if (!props.reportHide) {
  
          initCurverEchartsFun(props.ropeG2Data);
        }
      },
      { deep: true, immediate: false }
    );

    onMounted(() => {
      nextTick(() => {
        if (!props.reportHide) {

          initCurverEchartsFun(props.ropeG2Data);
        }
      });
    });

    return {
      ruleFrom,
      langObj
    };
  },
  data() {
    return {};
  },

  mounted() {},

  props: {
    basicReportInfo: Object, //  报告基本信息
    flawDailyDataClick: Object, //点击当前日期的损伤数据信息
    ruleFormParam: Object, //参数过滤
    pageNum: [Number, String],
    pageSize: [Number, String],
    reportHide: Boolean,
    index: [Number, String],
    ropeG2Data: Array,
  },
  components: {
    reportHeader,
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  width: 1000px;
  background-color: #fff;
  .main {
    width: 90%;
    margin: 0 auto;
    color: #333;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      width: 100%;
      text-align: center;
      padding: 15px;
      font-size: 18px;
      font-weight: 600;
      border-top: 1px solid #333;
      margin-top: 10px;
    }
    .report-wave {
      width: 1000px;
      height: 800px;
      background-color: #fff;
      transform: rotate(90deg);
      margin-top: 100px;
    }
  }
}
</style>