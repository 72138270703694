<template>
  <div class="station">
    <dv-border-box-10 :color="['#2E6099', '#0DE7C2']">
      <div class="inner" v-if="Object.keys(stationInfo).length > 0">
        <div class="title">
          <P
            ><span>{{ getEnglishName(stationInfo.buildingName) }}</span
            ><em>|</em
            ><span>{{ getEnglishName(stationInfo.motorRoomName) }}</span
            ><em>|</em
            ><span>{{ getEnglistTower(stationInfo.stationName) }}</span></P
          >
        </div>
        <div class="state">
          <div class="left">
            <div class="dd">
              <p
                :class="[filterSystemRunStateColor(stStateData.run_state)]"
              >
                {{ langObj.home.run_state }}:<span>{{
                  filterSystemRunState(stStateData.run_state, lang)
                }}</span>
              </p>
              <!-- <p><img class="state-img" :src="filterRingImg" alt="" /></p> -->
              <p @dblclick="changeAlarmDialogVisible">
                <em
                  class="ring"
                  :class="[filterRingBackGround(stStateData.state)]"
                ></em>
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.real_site }}:<span
                  >{{ filterFealSite(stStateData.pos) }}m</span
                >
              </p>
              <p>
                <img
                  :src="
                    filterSystemRunDirection(stStateData.direction, runImg)
                  "
                  alt=""
                />
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.rope_diameter }}:<span
                  >{{ stationInfo.ropeDiameter.toFixed(1) }}mm</span
                >
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.rope_specs }}:<span>{{
                  stationInfo.ropeSpecs
                }}</span>
              </p>
            </div>
            <div class="dd" style="color: #bdfcc9">
              <p>
                {{ langObj.home.day_mileage }}:<span
                  >{{ stStateData.daily_run_len.toFixed(3) }}km </span
                >
              </p>
            </div>
          </div>
          <div class="right">
            <div class="dd" style="justify-content: center">
              <p
                style="font-size: 0.2rem"
                :class="[filterSystemSafeColor(stStateData.state)]"
              >
                {{ filterSyetemSafeState(stStateData.state, lang) }}
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.real_speed }}:<span
                  >{{ stStateData.speed.toFixed(1) }}m/s</span
                >
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.rope_num }}:<span>{{
                  stationInfo.ropeCount
                }}</span>
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.rope_length }}:<span
                  >{{ stStateData.period_length?.toFixed(1) }}m</span
                >
              </p>
            </div>
            <div class="dd">
              <p>
                {{ langObj.home.run_time }}:<span
                  >{{ stStateData.daily_run_time.toFixed(3) }}h</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-10>
    <div class="alarm-dialog">
      <el-dialog
        v-model="dialogVisible"
        title="报警记录"
        width="60%"
        :close-on-click-modal="false"
      >
        <alarmList :id="id"></alarmList>
      </el-dialog>
    </div>
  </div>
</template>

<script setup>
import {
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  watch,
  toRefs,
  ref,
  computed,
} from "vue";
import {
  filterRingBackGroundHook,
  filterSystemSafeColorHook,
  filterSystemRunStateHook,
  filterSystemRunStateColorHook,
  filterSyetemSafeStateHook,
  filterSystemRunDirectionHook,
  httpGetStationInfoHook,
} from "@/hooks/home/station.js"; //定义 ruleFrom rules
// import { useRoute } from 'vue-router';
import { GetStState } from "@/common/api/index/api.js";
import alarmList from "@/components/detail/station/alarm/alarmList";
import { useStore } from "vuex";
import pinyin from "js-pinyin";

const props = defineProps({
  id: [Number, String],
});

const emitFun = defineEmits(["getStationInfo"]);
const store = useStore();


const runImg = reactive({
  stop: require("../../../../src/assets/icon/run_stop.png"),
  up: require("../../../../src/assets/icon/run_up.png"),
  down: require("../../../../src/assets/icon/run_down.png"),
});


const stStateData = reactive({
  state:0, //安全状态
  run_state:0, //运行状态
  direction:"", //运行方向
  pos: 0, //实时位置
  speed: 0, //实时速度
  period_length: 0, // 周期内最大检测长
  daily_run_len: 0, // 日运行总里程
  daily_run_time: 0, // 日运行总时长
});


let stationInfoObj = reactive({
  stationInfo: {},
});

const { stationInfo } = toRefs(stationInfoObj);

let dialogVisible = ref(false);
let timer = ref(null);

const langObj = computed(() => {
  return store.state.lang.list;
});

const lang = computed(() => {
  return store.state.lang.lang;
});

const filterRingBackGround = filterRingBackGroundHook();
const filterSystemSafeColor = filterSystemSafeColorHook();
const filterSystemRunState = filterSystemRunStateHook;
const filterSystemRunStateColor = filterSystemRunStateColorHook;
const filterSyetemSafeState = filterSyetemSafeStateHook;
const filterSystemRunDirection = filterSystemRunDirectionHook; //获取运行方向

const httpGetStationSingle = async (id) => {
  const station = await httpGetStationInfoHook(true, id); //使用的vue3 Hook
  emitFun("getStationInfo", station);
  stationInfoObj.stationInfo = station;

};

const httpGetStState = async (id) => {
  const data = {};
  data.id = id;
  const res = await GetStState(data);
  const stStateRes = res.data;
  for (const key in stStateData) {
    stStateData[key] = stStateRes[key];
  }
  store.commit("saveNewTimes", stStateRes.newest_detect);
};

const filterFealSite = (site) => {
  return site == 0 ? 0 : site.toFixed(1);
};

const changeAlarmDialogVisible = () => {
  dialogVisible.value = true;
};

const getEnglishName = (name) => {
  if (lang.value == "zh-CN") {
    return name;
  } else if (lang.value == "en") {
    return pinyin.getFullChars(name);
  }
};

const getEnglistTower = (name) => {
  if (lang.value == "zh-CN") {
    return name;
  } else if (lang.value == "en") {
    name = name.replace("塔吊", "tower crane");
    name = name.replace("号", " ");
    return name;
  }
};

watch(
  () => props.id,
  (id) => {
    httpGetStationSingle(id);
    httpGetStState(id);

  },
  { deep: true, immediate: false }
);

onMounted(async () => {
  nextTick(() => {
    timer.value = setInterval(() => {
      setTimeout(() => {
        // httpGetStationSingle(props.id);
        httpGetStState(props.id);
        
      }, 0);
    }, 2000);
  });
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>

<style lang="scss" scoped>
.station {
  height: 100%;
  //   background: linear-gradient(to bottom, #081c36, #08265d);
  background-color: #021e33;
  width: 100%;
  height: 80%;
  .inner {
    color: #fff;
    padding: 0.3125rem;
    .title {
      P {
        font-size: 0.25rem;
        font-weight: 600;
        text-align: center;
        em {
          margin: 0 5px;
        }
      }
    }
    .state {
      display: flex;
      justify-content: space-between;
      padding-top: 0.25rem;
      .left {
        width: 55%;
        .dd {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.5rem;
          p {
            font-size: 0.2rem;
            em {
              width: 0.3125rem;
              height: 0.3125rem;
              display: block;
              border-radius: 50%;
              margin-right: 0.025rem;
            }
            span {
              margin-left: 5px;
            }
          }
          img {
            width: 0.375rem;
          }
        }
      }
      .right {
        width: 42%;

        .dd {
          margin-left: 0.0625rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.5rem;
          p {
            font-size: 0.2rem;
            em {
              width: 0.3125rem;
              height: 0.3125rem;
              display: block;
              border-radius: 50%;
              margin-right: 0.025rem;
            }
            span {
              margin-left: 5px;
            }
          }
          img {
            width: 0.375rem;
          }
        }
      }
    }
  }
  & :deep .el-dialog {
    background-color: #021e33;
  }
  & :deep .el-dialog__header {
    text-align: center;
  }
  & :deep .el-dialog__header span {
    color: #fff;
  }
}
</style>